import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import Loading from '../components/loading/loading';
import MidAMStyle from '../components/styled/mid-am';
import GET_MID_AM from '../graphql/get-mid-am';
import Background from '../images/footer-pattern-sm.png';

export default function Page() {
  const [ midAMPage, setMidAMPage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_MID_AM, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && pageData) {
      setMidAMPage(pageData.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, midAMPage]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'Kinloch Golf Club'}
            description={seo?.description}
          />
          <MidAMStyle>
            <div style={{backgroundImage: `url(${Background})`, backgroundColor: `rgba(18, 40, 76)`}}>
              <div className="background">
                <div className="blue-overlay">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 page-title">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mid-am-section">
                <div className="col-md-12 champion-video">
                  <a className="video-link" href={midAMPage?.mid_am_acf?.championVideoLink} target="_blank" rel="noreferrer"><h3>{midAMPage?.mid_am_acf?.championVideoTitle}</h3></a>
                </div>
                <div className="col-md-6 mb-6">
                  <h3>Kinloch Golf Club</h3>
                  <a className="video-link" href={midAMPage?.mid_am_acf?.videoLink} target="_blank" rel="noreferrer">A Video Review of Kinloch</a>
                  <a href={midAMPage?.mid_am_acf?.image1Link} target="_blank" rel="noreferrer">
                    <img
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    src={midAMPage?.mid_am_acf?.clubImage1.sourceUrl} 
                    alt={midAMPage?.mid_am_acf?.clubImage1.altText} 
                  /></a>
                  {/* <a href={midAMPage?.mid_am_acf?.courseTourLink1} className="bottom-left"><h2>Course Tour</h2></a>
                  <a href={midAMPage?.mid_am_acf?.clubHistoryLink1} className="bottom-right"><h2>Club History</h2></a> */}
                </div>
                <div className="col-md-6 mb-6">
                  <h3>Independence Golf Club</h3>
                  <a href={midAMPage?.mid_am_acf?.image2Link} target="_blank" rel="noreferrer">
                    <img className="image2"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    src={midAMPage?.mid_am_acf?.clubImage2.sourceUrl} 
                    alt={midAMPage?.mid_am_acf?.clubImage2.altText} 
                  /></a>
                  {/* <a href={midAMPage?.mid_am_acf?.courseTourLink2} className="bottom-left"><h2>Course Tour</h2></a>
                  <a href={midAMPage?.mid_am_acf?.clubHistoryLink2} className="bottom-right"><h2>Club History</h2></a> */}
                </div>
              </div>
            </div>
          </MidAMStyle>
        </>
      )}
    </>
  )
}